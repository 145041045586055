.butn {
  background: #152850;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 12px 30px;
  line-height: normal;
  font-weight: 500;
  text-transform: none !important;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .butn {
    padding: 10px 28px;
  }
}
@media screen and (max-width: 767px) {
  .butn {
    padding: 8px 26px;
  }
}
.butn span {
  z-index: 9;
  position: relative;
}
.butn:hover {
  color: #152850;
  border-radius: 3px;
}
.butn:hover:after {
  width: 100%;
  border-radius: 3px;
}
.butn:after {
  content: "";
  position: absolute;
  border-radius: 4px;
  bottom: 6px;
  left: 0;
  height: 2px;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
  width: 0;
  background: #ffc757;
  color: #152850;
  height: 100%;
  top: 0;
  z-index: 1;
}
.butn.white {
  background: #fff;
  color: #232323;
}
.butn.white:hover {
  color: #fff;
  border-radius: 3px;
}
.butn.white:after {
  background: #232323;
  border-radius: 3px;
}
.butn.theme {
  background: #152850;
  color: #fff;
}
.butn.theme span {
  color: #fff;
}
.butn.theme:hover {
  color: #fff;
  border-radius: 3px;
}
.butn.theme:after {
  background: #232323;
  border-radius: 3px;
}
.butn.theme .white-hover:hover {
  color: #232323;
}
.butn.theme .white-hover:hover span {
  color: #232323;
}
.butn.theme .white-hover:after {
  background: #fff;
  border-radius: 3px;
}
.butn.small {
  padding: 8px 20px;
}
@media screen and (max-width: 991px) {
  .butn.small {
    padding: 7px 16px;
  }
}
@media screen and (max-width: 767px) {
  .butn.small {
    padding: 6px 14px;
  }
}
.butn.medium {
  padding: 10px 20px;
}
@media screen and (max-width: 991px) {
  .butn.medium {
    padding: 8px 18px;
  }
}
@media screen and (max-width: 767px) {
  .butn.medium {
    padding: 6px 16px;
  }
}

.btn-primary:active, .btn-primary:focus {
  background-color: #ffc857 !important;
  color: #232323 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: none !important;
}