.filter-section .filter-label {
  font-size: 11px;
  color: #616161;
  font-weight: 400;
  line-height: 20px;
}
.filter-section .filter-option {
  font-size: 12px !important;
}
.filter-section .form-control.date-select {
  font-size: 12px !important;
  border: 1px solid #909090;
  padding-right: 10px;
}
.filter-section input {
  margin-bottom: 5px;
}
.filter-section .input-icon {
  position: absolute;
  font-size: 16px;
  right: 23px;
  top: 33px;
  pointer-events: none;
}
.filter-section .dropdown {
  border: 1px solid #909090;
}
.filter-section .bootstrap-select .dropdown-menu {
  min-width: unset;
}