.profile-page .section-title {
  font-size: 22px;
  color: black;
  margin-bottom: 4px;
}
@media screen and (max-width: 767px) {
  .profile-page .section-title {
    margin-top: 15px;
    margin-bottom: 0;
  }
}
.profile-page .profile-form {
  margin-top: 15px;
}
.profile-page .nav-pills {
  border-bottom: 1px solid #bfbfbf;
}
.profile-page .nav-pills .nav-link.active, .profile-page .nav-pills .nav-pills .show > .nav-link {
  background-color: #4c0034;
}
.profile-page .custom-switch {
  padding-top: 0.75rem;
  margin-left: 20px;
}
.profile-page .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #09324c;
  border-color: #09324c;
}
.profile-page .question {
  border-top: 1px solid #09324c;
  padding-top: 10px;
  margin-top: 10px;
  font-weight: 800;
}