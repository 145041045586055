footer {
  background-color: #263284;
  background-image: url("/images/footer-curve.svg");
  background-repeat: no-repeat;
  background-position: center -2%;
  background-size: 102% auto;
  padding-top: 100px;
  padding-bottom: 60px;
}
footer .logo-container {
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1.5rem 3rem;
  background-color: rgba(255, 255, 255, 0.6666666667);
  border-radius: 10px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
footer .logo-container img {
  max-height: 80px;
}
footer .logo-container .ibr-logo {
  max-height: none;
  margin-bottom: 2rem;
}
footer .logo-container .col-10 {
  text-align: center;
  margin-bottom: 2rem;
}
footer .info-text {
  margin-top: 2rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.2rem;
}