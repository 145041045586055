.carousel-container {
  width: 35% !important;
  position: relative;
  float: right;
  margin: 20px 0 0 15px;
}
.carousel-container.width-30 {
  width: 30% !important;
}
.carousel-container.width-100 {
  width: 100% !important;
  margin: 0;
}
.carousel-container.clear-float {
  float: unset !important !important;
}
.carousel-container figure {
  margin-bottom: 0;
}