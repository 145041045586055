.scroll-to-top {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: #152850;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 20px;
  padding: 0 8px;
  visibility: hidden;
  opacity: 0;
  border-radius: 8px 8px 0 0;
  background-color: white;
  border: 1px solid #152850;
  width: auto;
  height: 35px;
  line-height: 35px;
  z-index: 9999;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.scroll-to-top i {
  color: #152850;
}
.scroll-to-top:hover, .scroll-to-top:focus {
  color: #152850;
  background: #fff;
  text-decoration: none;
}
.scroll-to-top:hover i, .scroll-to-top:hover .icon, .scroll-to-top:focus i, .scroll-to-top:focus .icon {
  color: #152850;
}
.scroll-to-top:hover:visited, .scroll-to-top:focus:visited {
  color: #152850;
  text-decoration: none;
}
.scroll-to-top .icon {
  color: #152850;
  width: 18px;
  height: 18px;
  margin-left: 8px;
}
.scroll-to-top.show {
  visibility: visible;
  opacity: 1;
}