h4 span.detail {
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #ccc;
}
h4 span.detail strong {
  font-weight: 700;
}

.section-heading {
  margin-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .section-heading {
    margin-bottom: 30px;
  }
}
.section-heading strong {
  font-weight: 600;
}
.section-heading p {
  font-size: 15px;
  font-weight: 400;
  padding-top: 15px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .section-heading p {
    padding-top: 10px;
    font-size: 14px;
  }
}
.section-heading span {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #5085a5;
  line-height: 200%;
  margin-bottom: 10px;
}
.section-heading h1, .section-heading h2, .section-heading h3, .section-heading h4, .section-heading h5, .section-heading h6 {
  position: relative;
  padding-bottom: 10px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  line-height: 140%;
}
.section-heading h1:after, .section-heading h2:after, .section-heading h3:after, .section-heading h4:after, .section-heading h5:after, .section-heading h6:after {
  content: "";
  display: block;
  width: 175px;
  height: 3px;
  background: #ffc757;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.section-heading .badge {
  display: inline-block;
  padding: 7px 19px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 14px;
  color: #5085a5;
  border: 1px solid #5085a5;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}
.section-heading.half {
  margin-bottom: 30px;
}
.section-heading.half.less-margin {
  margin-bottom: 20px;
}
.section-heading.white h1, .section-heading.white h2, .section-heading.white h3, .section-heading.white h4, .section-heading.white h5, .section-heading.white h6 {
  color: #fff;
}
.section-heading.white p {
  color: #fff;
}
.section-heading.white span {
  color: #fff;
}
.section-heading.grey h1, .section-heading.grey h2, .section-heading.grey h3, .section-heading.grey h4, .section-heading.grey h5, .section-heading.grey h6 {
  color: #b7b7b7;
}
.section-heading.grey h1:after, .section-heading.grey h2:after, .section-heading.grey h3:after, .section-heading.grey h4:after, .section-heading.grey h5:after, .section-heading.grey h6:after {
  background: #b7b7b7;
}
.section-heading.grey span {
  color: #b7b7b7;
}
.section-heading.left {
  text-align: left;
}
.section-heading.left h1, .section-heading.left h2, .section-heading.left h3, .section-heading.left h4, .section-heading.left h5, .section-heading.left h6 {
  padding-bottom: 10px;
}
.section-heading.left h1:after, .section-heading.left h2:after, .section-heading.left h3:after, .section-heading.left h4:after, .section-heading.left h5:after, .section-heading.left h6:after {
  margin: 0;
  left: 0;
}
.section-heading.subheader {
  margin-bottom: 0 !important;
  text-align: left !important;
}
.section-heading.subheader h1, .section-heading.subheader h2, .section-heading.subheader h3, .section-heading.subheader h4, .section-heading.subheader h5, .section-heading.subheader h6 {
  padding-bottom: 7px !important;
}
.section-heading.subheader :after {
  width: 60px;
  height: 2px;
}
.section-heading.subheader .icon {
  margin-right: 10px;
}

.left-title {
  position: relative;
}
.left-title:before {
  content: "";
  background: #5085a5;
  width: 15px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 10px;
}
.left-title.white:before {
  background: #fff;
}