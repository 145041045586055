.recoverct-datatable {
  width: 100%;
  /*	table-layout: fixed;
  word-wrap: break-word;*/
  /*		table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc {
  	padding-right: 0.75em !important;

  	&:before,
  	&:after {
  		display: none;
  	}
  }*/
  /*	.dataTables_wrapper .dataTables_filter {
  		float: unset !important;
  	}
  */
}
.recoverct-datatable .dtfc-right-top-blocker {
  background-color: rgba(39, 73, 110, 0) !important;
}
.recoverct-datatable input {
  margin-bottom: 0;
  padding: 2px 10px;
}
.recoverct-datatable th {
  background-color: #152850 !important;
  color: white !important;
  vertical-align: middle;
  line-height: 20px;
  font-size: 12px;
}
.recoverct-datatable th select {
  margin-bottom: 0;
}
.recoverct-datatable.selectable-rows table.dataTable tbody td:hover {
  cursor: pointer;
}
.recoverct-datatable table.dataTable tbody td {
  vertical-align: middle;
  text-align: center;
}
.recoverct-datatable table.dataTable tbody td:hover {
  cursor: default;
}
.recoverct-datatable table.dataTable tbody td.overall-data {
  font-weight: bold;
}
.recoverct-datatable table.dataTable tbody td.link {
  color: #31708e;
  text-decoration: underline;
  cursor: pointer;
}
.recoverct-datatable table.dataTable tbody td.details-control {
  background: url("/images/util/details_open.png") no-repeat center center;
  cursor: pointer;
}
.recoverct-datatable table.dataTable tbody tr.shown td.details-control {
  background: url("/images/util/details_close.png") no-repeat center center;
}
.recoverct-datatable table.dataTable tbody tr.overall-data td {
  font-weight: bold;
}
.recoverct-datatable table.dataTable tbody tr.detail-row td {
  vertical-align: top;
}
.recoverct-datatable table.dataTable.table-striped tbody tr.odd td {
  background-color: #e4e4e4;
}
.recoverct-datatable thead .sorting:before, .recoverct-datatable thead .sorting:after,
.recoverct-datatable thead .sorting_asc:before, .recoverct-datatable thead .sorting_asc:after,
.recoverct-datatable thead .sorting_desc:before, .recoverct-datatable thead .sorting_desc:after {
  display: none !important;
}
.recoverct-datatable thead tr > th:not(.sorting_disabled) {
  padding-right: 0.75em !important;
}
.recoverct-datatable.right-filter .dataTables_wrapper .dataTables_filter {
  float: right !important;
}
.recoverct-datatable.right-filter .dataTables_wrapper .dataTables_filter label {
  padding-right: 0;
  font-size: 12px;
}
.recoverct-datatable .top-controls .table-message {
  font-size: 12px;
  line-height: 10px;
  margin-top: 5px;
  font-style: italic;
}
.recoverct-datatable label {
  font-size: 12px;
  line-height: 1.5;
  width: 100%;
}
.recoverct-datatable input {
  margin: 0;
  margin-left: 0;
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  border: 1px solid #909090;
  border-radius: 0;
}
.recoverct-datatable select {
  margin: 0;
}
.recoverct-datatable .form-group {
  margin-bottom: 0.5em;
}
.recoverct-datatable .dropdown {
  border: 1px solid #909090;
}
.recoverct-datatable .filter-option {
  font-size: 12px !important;
}
.recoverct-datatable .dt-buttons {
  float: right;
  -ms-flex-item-align: center;
      align-self: center;
  margin-left: 10px;
}
.recoverct-datatable .dt-buttons button {
  padding: 2px 11px 1px 11px;
  height: 32px;
  width: 36px;
  max-width: 36px;
  background-color: #616161;
}
.recoverct-datatable .dt-buttons button:hover {
  /*color: black;*/
  background-color: #152850;
}
.recoverct-datatable .progress {
  height: 30px;
  border-radius: 0;
}
.recoverct-datatable .progress .progress-bar {
  background-color: #e07c3e;
  color: #000;
  font-size: 10px;
}
.recoverct-datatable .progress .progress-bar.hide {
  color: #f2f2f2;
}
.recoverct-datatable .progress .label {
  height: 20px;
  background-color: rgba(39, 73, 110, 0);
  top: 14px;
}
.recoverct-datatable .condense-vertical td {
  padding: 0.25rem 0.75rem;
}
.recoverct-datatable .pagination .page-item .page-link {
  color: #6c757d;
}
.recoverct-datatable .pagination .page-item.disabled .page-link {
  color: #dee2e6;
}

.bootstrap-select .filter-option {
  font-size: 14px;
  margin-right: 8px;
}
.bootstrap-select .dropdown-menu li.disabled a {
  font-size: 11px;
}
@media screen and (max-width: 767px) {
  .bootstrap-select .dropdown-menu {
    max-width: 100%;
  }
}
.bootstrap-select.transparent .btn-light {
  background-color: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}
.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  left: 12px;
}
.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 10px;
  margin-left: 8px;
}

div .dataTables_wrapper div.dataTables_filter {
  display: block;
}
div .dataTables_wrapper div.dataTables_filter label {
  font-size: 12px;
  line-height: 1.5;
  width: 100%;
}
div .dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  border: 1px solid #909090;
  border-radius: 0;
}