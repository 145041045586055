.jstree .jstree-themeicon-custom {
  font-size: 18px;
  padding: 3px;
  height: 18px !important;
  width: 18px !important;
}
.jstree .fa-folder.jstree-themeicon-custom {
  color: #96946f;
}
.jstree .fa-file-pdf.jstree-themeicon-custom {
  color: #ba1200;
}
.jstree .fa-file-word.jstree-themeicon-custom {
  color: #2b589b;
}
.jstree .fa-file-excel.jstree-themeicon-custom {
  color: #217446;
}
.jstree .fa-file-powerpoint.jstree-themeicon-custom {
  color: #b8462b;
}
.jstree .jstree-default .jstree-wholerow-hovered {
  background-color: #f9fafb;
}
.jstree .jstree-default .jstree-wholerow-clicked {
  background: #cbd4dd;
  color: #575757;
}
.jstree .jstree-default .jstree-search {
  color: #575757;
}