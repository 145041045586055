section {
  padding: 70px 0;
}

.container.full-width {
  max-width: 90vw !important;
}

.image-row {
  margin: 75px 0;
}

a:hover, aactive {
  color: #27496E;
  text-decoration: none;
}

.bg-theme {
  background-color: #4c0034;
}

.bg-theme-90 {
  background: rgba(0, 183, 192, 0.9);
}

.bg-theme-80 {
  background: rgba(0, 183, 192, 0.8);
}

.bg-theme-75 {
  background: rgba(0, 183, 192, 0.75);
}

.bg-gainsboro {
  background-color: #dae1e7;
}

.bg-ltgrey {
  background-color: #f2f2f2;
}

.theme-overlay[data-overlay-dark]:before,
.theme-overlay[data-overlay-light]:before {
  background: #27496e;
}

.text-theme-color {
  color: #27496e;
}

.text-content-big p {
  font-weight: 400;
  line-height: 200%;
  margin-bottom: 25px;
  font-size: 15px;
}

.shadow-primary {
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.line-through {
  text-decoration: line-through;
}

.readmore {
  font-weight: 700;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.readmore:after {
  content: "\f105";
  font-size: 16px;
  vertical-align: top;
  font-family: Font Awesome\ 5 Free;
  padding-left: 8px;
  vertical-align: middle;
}
.readmore :hover {
  color: #27496e;
}

.link-icon {
  margin-left: 7px;
}

ul.primary-list {
  list-style-position: outside;
  margin-left: 10px;
}
ul.primary-list li {
  list-style: none;
  margin-top: 0.75rem;
  padding-left: 15px;
  position: relative;
}
ul.primary-list li .icon {
  font-weight: 900;
  margin-right: 10px;
  color: #01909E;
}
ul.secondary-list {
  list-style-position: inside;
  margin-left: 30px;
}
ul.secondary-list li {
  list-style: disc;
  /*margin-top: .75rem;*/
  /*padding-left: 15px;*/
  /*position: relative;*/
}

ol {
  margin-left: 1em;
}
ol li::marker {
  color: #616161;
}

@media screen and (max-width: 575px) {
  .no-mobile-margin-15px-bottom {
    margin-bottom: 0 !important;
  }
  .mobile-margin-15px-bottom {
    margin-bottom: 15px;
  }
  .mobile-margin-20px-bottom {
    margin-bottom: 20px;
  }
  .mobile-margin-30px-bottom {
    margin-bottom: 30px;
  }
}
.bio {
  margin-bottom: 20px;
}
.bio > div {
  background-color: rgba(76, 0, 52, 0.1411764706);
  padding: 25px 20px 25px;
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bio > div > strong {
  color: black;
  font-size: 18px;
  letter-spacing: 0.26px;
  padding-bottom: 15px;
  margin: 0 0 15px;
  line-height: normal;
  border-bottom: 2px solid black;
  display: block;
  font-weight: 700;
}
.bio > div > em {
  display: block;
  width: 100%;
}
.bio > div > em.project-role {
  margin-bottom: 5px;
  font-size: 12px;
  font-style: normal;
}

li::marker {
  color: #263284;
}

.placeholder {
  position: relative;
  aspect-ratio: 1/1;
  background-color: #d0d2e2;
  width: 100%;
}
.placeholder::after {
  content: "Placeholder";
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
}

.enroll-button {
  padding: 0.5rem 1.75rem;
  background-color: #ED3340;
  color: #FAFAFA;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.enroll-button:hover {
  color: #FAFAFA;
  text-decoration: underline;
}

.hero {
  position: relative;
  min-height: 200px;
  padding-bottom: 100px;
}
.hero::after {
  content: "";
  background-image: url("/images/hero-curve.svg");
  background-repeat: no-repeat;
  background-position: center 102%;
  background-size: 102% auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
}

.section-white {
  background-color: #FAFAFA;
  padding: 3rem 0;
}

.section-yellow {
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#FAFAFA)), url("/images/about-hero-bg.png");
  background: linear-gradient(transparent, #FAFAFA), url("/images/about-hero-bg.png");
}

.cds-image-container {
  border-radius: 10px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.cds-card {
  border-radius: 10px;
  padding: 1.5rem 3rem;
  border-top: 5px solid #ED3340;
  background-color: #FAFAFA;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.hero-card {
  min-height: 100px;
  border-top-color: #263284;
}

.language-button {
  color: #263284;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 11px;
  margin-right: 20px;
  display: block;
  text-align: left;
}
.language-button:hover {
  color: #263284;
}